/*******Common**********/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* Container */
.default-container{
  display: flex;
  width: 100%;
}
.content-wrapper{
  width: 100%;
  background-color: #efefef
}
.content-body{
  min-height: calc(100vh - 110px);
  background-color: #efefef
}
.content-container{
  background-color: #fff;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
}
/********* Header *********/
.app-header{
  height: 50px;
  position: sticky;
  top: 0;
  z-index: 999;
  margin-left: 1px !important;
}
.app-header.ui.secondary.menu, .app-footer.ui.secondary.menu{
  background: #fff;
  border-bottom: 1px solid #d8dbe0;
}
.app-footer.ui.secondary.menu{
  padding: 0px;
  margin: 0px;
  height: 45px;
}

/* Sidebar */
.sidebar-container{
  width: 315px;
  /* height: 100vh; */
}
.sidebar-content{
  height: calc(100vh - 95px);
  overflow-x: hidden;
}

.sidebar-container ::-webkit-scrollbar {
  display:none;
  /* width: 5px; */
}

.sidebar-content .ui.vertical.menu{
  width: 100%;
}
.sidebar-content .ui.accordion .title:not(.ui){
  color: #FFF
}
.sidebar-content .ui.vertical.menu{
  background-color: #2c3646;
}
.sidebar-content .ui.accordion .title:not(.ui){
  display: flex;
  justify-content: space-between;
}
.sidebar-header{
  height: 50px;
  border-bottom: 1px solid #FFF;
  background-color: #FFF;
  position: sticky;
  top: 0;
  z-index: 980 !important;
  text-align: center;
} 
.menuItem{
  float: left;
}
.ui.segment{
  border: 0px;
  border-radius: 0px;
}
.ui.visible.slide.along.sidebar {
  width: 256px;
  background-color: rgba(44,54,70);
}
.ui.visible.slide.along.sidebar::-webkit-scrollbar {
  width: 5px;
}
.ui.labeled.icon.menu .item{
  flex-direction: column;
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  padding: 1.5rem 1rem;
  text-decoration: none;
  white-space: nowrap;
}
.ui.labeled.icon.menu .item:hover{
  background-color: rgba(255,255,255,.15);
}
.sidebar-footer{
  height: 45px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-footer i{
  color: #fff;
  position: absolute;
}
.sidebar-straight-line{
  border-bottom: 1px solid #8c8c8c;
  width: 100%;
}
.menu-item.main-menu .left-icon{
  margin-right: 10px;
}
.sub-menu{
  padding: 10px 0px;
}
.sub-menu:hover{
  background: rgba(255,255,255,.15);
}
.sub-menu .content a.item:hover{
  background: rgba(255,255,255,.15);
}
.sub-menu .title{
  display: inline-block !important;
  justify-content: flex-start !important;
  width: 100%;
  padding-left: 14px !important;
}
.sub-menu .left-icon{
  float: left !important;
  margin-right: 10px;
}
.sub-menu span{
  float: left !important;
}
.sub-menu .right-icon{
  float: right !important;
}
.sub-menu .content.active{
  background: #000;
}

/* Sidebar pro */
.pro-sidebar > .pro-sidebar-inner{
  background-color: rgba(44,54,70);
}
.pro-sidebar .pro-menu .pro-menu-item {
  font-size: 16px;
  color: #fff;
  border-bottom: 1px solid #444e5f;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
  padding: 12px 35px 12px 20px;
}
/* Login Page */
.login-body{
  height: 100vh;
  background-color: #ebedef;
}
.login-form-content{
  width: 400px;
  align-self: center;
  background-color: #fff;
  padding: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 21px -9px rgba(0,0,0,1);
-moz-box-shadow: 0px 0px 21px -9px rgba(0,0,0,1);
box-shadow: 0px 0px 21px -9px rgba(0,0,0,1);
}
.login-body .ui.placeholder.segment .button, .ui.placeholder.segment .field, .ui.placeholder.segment textarea, .ui.placeholder.segment>.ui.input{
  max-width: 100%;
}
.login-logo{
  margin: 0 auto;
  margin-bottom: 20px;
}
.login-button-container{
  vertical-align: middle;
  margin-top: 30px;
}
.login-button-container a{
  color: red;
}
.forgon-button-container a{
  color: blue;
}

.login-button{
  float: right;
}

/* Spinner */
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  margin-right: 2px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}
.revert-container{
  display: revert;
  margin-bottom: 50px;
  margin-right: 15px;
}
.revert-container button{
  float: right;
}
.ui.form .field.disabled :disabled{
  border: 1px solid #9e9e9e;
}
.ui.form .field.disabled>label, .ui.form .fields.disabled>label{
  opacity: 1;
}
.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled{
  opacity: 1;
}
.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
  opacity: .65;
}
.subheader-searchbar{
  width: 100%;
  position: absolute;
  left: 0;
}
.subheader-searchbar .ui.selection.dropdown{
  min-width: calc(100% / 6);
  /* padding: 5px; */
}
.subheader-searchbar .ui.input{
  width: calc(100% / 6);
  padding: 5px;
}
.react-datepicker-ignore-onclickoutside{
  height: 38px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.subheader-searchbar .ui.input input{
  margin-right: 10px;
}
.sc-dlnjPT.bYYDbU.rdt_TableHeadRow{
  background-color: rgb(33 133 208);
}
.ui.segment.forum-segmont{
  position: relative;
    box-shadow: 0 0 black;
    border-radius: 0px;
    border: 0px;
}
.row.grid-row{
  padding-bottom: 0px !important
}
.ui.basic.buttons.align-self-center{
  margin: 0 auto;
}
.field.align-self-center{
  margin: 0 auto;
}
.ui.celled.table.dataTable{
  border-radius: 0px;
}
.ui.celled.table.dataTable th{
  background-color: rgb(33 133 208);
  border-radius: 0px !important;
  color: #FFF !important;
}
.left.floated.five.wide.column.paginationIndex{
    display: flex;
    align-items: center;
}
/* .ui.celled.table.dataTable .ui.input{
  float: right;
} */
table.dataTable tbody tr td:last-child{
  text-align: right;
}
table.dataTable thead tr th:last-child{
  text-align: right;
}
.ui.table td{
  padding: 5px 10px;
}
.ui.segment.loaderHeight{
  height: calc(calc(100vh - 250px));
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
}
.ui.disabled.input.tableDesabledInput input{
  background-color: #e8e8e8;
}
.ui.mini.basic.modal.transition.visible.active.modelContentCenter{
  width: 265px;
  background-color: #FFF;
  border-radius: 5px;
}
.ui.mini.basic.modal.transition.visible.active.modelContentCenter i{
    position: absolute;
    top: -15px;
    right: -15px;
    color: #FFF;
    border: 1px solid #FFF;
    border-radius: 50%;
    padding: 7px;
    background: #000;
}
.ui.mini.basic.modal.transition.visible.active.modelContentCenter .content{
    justify-content: center;
    display: flex;
}
.ui.mini.basic.scrolling.modal.transition.visible.active.modelContentCenter .content i.times.circle.outline.large.icon{
  position: absolute;
  right: 0px;
  top: 8px;
}
.ui.grid.grid-searchbar input{
  margin-right: 10px;
  margin-left: 10px;
}
.table-search{
  width: 100%;
}
.table-search .ui.icon.input{
  float: right;
}
.pack-section{
    width: 100%;
    margin: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: auto;
}
.headerTable{
  width: 130% !important;
}
.fest-date-picker .react-datepicker-wrapper{
  width: 100%;
}
td.invoice-price-summary .collapsing.right.aligned{
  border-left: 0
}